import React from 'react'
import PageTitle from '../../components/PageTitle'
import Layout from '../../components/Layout'
import Button from '../../components/Button'

import Obfuscate from 'react-obfuscate'
import styled from 'styled-components'
import accs from './school_logos/accs.jpg'
import tuskegee from './school_logos/tuskegee.gif'
import ua from './school_logos/the_university_of_alabama.png'
import uwa from './school_logos/university_of_west_alabama.jpg'
import wallace from './school_logos/wallace.png'
import uah from './school_logos/uah_blue.png'
import troy from './school_logos/troy.png'
import wallacehanceville from './school_logos/wallacehanceville.png'
import au from './school_logos/au.png'
import snead from './school_logos/snead.png'
import usa from './school_logos/usa.jpg'
import jfd from './school_logos/j_f_drake_state.png'
import jscc from './school_logos/jscclogo.png'
import wallaceselma from './school_logos/wallace-selma.jpg'
import escc from './school_logos/escc.png'
import lbw from './school_logos/lbw.jpg'
import uab from './school_logos/uab.jpg'
import aum from './school_logos/aum.jpg'
import caca from './school_logos/CACA.png'
import asu from './school_logos/ASU.jpg'
import una from './school_logos/UNA.png'
import wallacedothan from './school_logos/wallace-dothan.jpg'
import wallacestate from './school_logos/WSCC.png'
import mmi from './school_logos/MMI.png'
import green from './school_logos/GREENVILLE.png'

class Leadership extends React.Component {
  render() {
    return (
      <Layout>
        <PageTitle name="Leadership" />

        <Row>
          <h3>Executive Committee 2023-24</h3>
          <div>
            {leaders.map((link, i) => (
              <Contact key={i}>
                  <Img src={link.image} alt={link.image} />
                <Text>
                  <Title>{link.position}</Title>
                  <p>
                    <Obfuscate email={link.email}>{link.name}</Obfuscate>
                    <br />
                    {link.institution}
                  </p>
                  <br />
                </Text>
              </Contact>
            ))}
          </div>
        </Row>
        <Row>
          <h3>Nominating Committee</h3>
          <div>
            {nc.map((link, i) => (
              <Contact key={i}>
                  <Img src={link.image} alt={link.image} />
                <Text>
                  <p>
                    <Obfuscate email={link.email}>{link.name}</Obfuscate>
                    <br />
                    {link.institution}
                  </p>
                </Text>
              </Contact>
            ))}
          </div>
        </Row>
        <Row>
          <h3>Members-at-Large</h3>
          <div>
            {mal.map((link, i) => (
              <Contact key={i}>
                  <Img src={link.image} alt={link.image} />
                <Text>
                  <p>
                    <Obfuscate email={link.email}>{link.name}</Obfuscate>
                    <br />
                    {link.institution}
                  </p>
                </Text>
              </Contact>
            ))}
          </div>
        </Row>
        <h3>Executive Committee Resources</h3>
        <a href="https://www.dropbox.com/home/alair%20board">
          <Button>Board Documents</Button>
        </a>
      </Layout>
    )
  }
}
export default Leadership

const mal = [
  // {
  //   name: 'Emily Rothenbacher',
  //   institution: 'The University of Alabama',
  //   email: 'evrothenbacher@ua.edu',
  //   image: ua,
  // },
  // {
  //   name: 'Preston Robinson',
  //   institution: 'Tuskegee University',
  //   email: 'probinson@tuskegee.edu',
  //   image: tuskegee,
  // },
]

const nc = [
  {
    name: 'Anna Hodges',
    institution: '',
    email: '',
    // image: uab,
  },
  {
    name: 'Savannah Jones',
    institution: '',
    email: '',
    // image: mmi,
  },
]

const leaders = [
  {
    position: 'President',
    name: 'Preston Robinson',
    institution: 'Tuskegee University',
    email: 'probinson@tuskegee.edu',
    image: tuskegee,
  },
  {
    position: 'Vice President/Conference Chair',
    name: 'Jessica Carroll',
    institution: 'Alabama Community College System',
    email: 'Jessica.carroll@accs.edu',
    image: accs,
  },
  {
    position: 'Past-President',
    name: 'Ginny Cockerill',
    institution: 'Greenville Technical College',
    email: 'ginny.cockerill@gvltec.edu',
    image: green,
  },
  {
    position: 'Secretary',
    name: 'Michelle Tin',
    institution: 'The Univeristy of Alabama',
    email: 'mttin@ua.edu',
    image: ua,
  },
  {
    position: 'Treasurer',
    name: 'Tish Worthington',
    institution: 'The University of Alabama',
    email: 'lworthington@ua.edu',
    image: ua,
  }
]

const Row = styled.div`
  display: inline-block;
  margin-bottom: 5em;
`

const ImgWrap = styled.div`
  
`
const Text = styled.div`
  vertical-align: middle;

  padding: 1.1em 0 0 25px;
  display: inline-block;
  @media (max-width: 40em) {
    padding: 0 0 0 0;
    width: 100%;
  }
`
const Img = styled.img`
  vertical-align: middle;
  width: 150px;
  @media (max-width: 40em) {
    margin: 0 auto;
  }
`

const Contact = styled.div`
  float: left;
  overflow: auto;
  text-align: left;
  width: 100%;
  &:hover {
    fill: $brand-dark;
    fill: url(#grad1);
  }
  @media (max-width: 40em) {
    float: none;
    margin: 0.25em auto;
    width: 100%;
    text-align: center;
  }
`

const Title = styled.h4`
  margin: 1em 0 0 0;
`
